:root {
  --gabi-orange: #EA7D28;
  --gabi-red: #CE2F00;
  --gabi-gradient: linear-gradient(175deg, rgba(234,125,40,1) 20%, rgba(206,47,0,1) 100%); 

  --grey: #222426;
  --grey-a080: rgba(34, 36, 38, 0.8);
  --grey-a060: rgba(34, 36, 38, 0.6);
  --grey-a030: rgba(34, 36, 38, 0.3);
  --grey-a020: rgba(34, 36, 38, 0.2);
  --grey-a010: rgba(34, 36, 38, 0.1);
  --grey-a005: rgba(34, 36, 38, 0.05);
  --grey-a003: rgba(34, 36, 38, 0.03);
  --grey-005: #f1f1f1;
  --grey-003: #F8F8F8;
  --white: white;
  --white-a080: rgba(255,255,255,0.8);
  --white-a060: rgba(255,255,255,0.6);
  --white-a030: rgba(255,255,255,0.3);
  --white-a020: rgba(255,255,255,0.2);
  --white-a010: rgba(255,255,255,0.1);
  --white-a005: rgba(255,255,255,0.05);
  --white-a003: rgba(255,255,255,0.03);
}