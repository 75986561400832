* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: var(--font-roboto);
  color: var(--grey-a080);
}

#root {
  width: 100%;
  height: 100%;
  min-height: 100%;
  position: fixed;
  overflow: hidden;
  scrollbar-color: var(--grey-a030) var(--grey-a005);
  scrollbar-width: thin;
}

section {
  position: relative;
}

a, a:visited, a:active {
  text-decoration: none;
  color: var(--grey);
}

ul, ol {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

.flex-row {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.justify-align-center {
  justify-content: center;
  align-items: center;
  text-align: center;
}

.scroll {
  scrollbar-color: var(--grey-a030) var(--grey-a005);
  scrollbar-width: thin;
}

@media (max-width: 1200px) {

  #root {
    overflow-y: auto;
    overflow-x: hidden;  
  }

}