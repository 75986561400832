/* ------- TEXTOS */

:root {
  --font-yanone: 'Yanone Kaffeesatz', sans-serif;
  --font-roboto: 'Roboto', sans-serif;
}

.text-display {
  font-family: var(--font-bree-serif);
  margin: 0;
  font-size: 70px;
  font-weight: 400;
}

.text-title {
  font-family: var(--font-bree-serif);
  margin: 0;
  font-size: 40px;
  display: table;
  padding: 0 10px;
  font-weight: 400;
}

.text-title::after {
  background-color: var(--light-purple);
  width: calc(100% + 20px);
  content: '';
  height: 20px;
  bottom: 22px;
  position: relative;
  display: block;
  z-index: -1;
  left: -10px;
}

.text-subtitle {
  font-family: var(--font-sarabun);
  margin: 0;
  font-size: 24px;
  font-weight: 700;
}

.text-subtitle-small {
  font-family: var(--font-roboto);
  margin: 0;
  font-size: 20px;
  font-weight: 500;
}

.text-body {
  font-family: var(--font-roboto);
  color: var(--grey-a080);
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.text-body-small {
  font-family: var(--font-roboto);
  color: var(--grey-a080);
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.text-caption {
  font-family: var(--font-roboto);
  color: var(--grey-a080);
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

