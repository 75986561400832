/* ------------- MAINTENENCE  */

main {
  min-height: 100%;
  max-width: 1400px;
  margin: 0 auto;
  flex-wrap: wrap;
}

section {
  height: 100%;
  width: 50%;
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
}

#info {
  position: fixed;
  width: 680px;
  min-width: 680px;
}

#about {
  margin-left: 680px;
  position: absolute;
  padding: 40px 3% 0 40px ; 
  width: calc(100% - 680px);
  max-width: 720px;
}

.card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 3px 40px 0px var(--grey-a010);
  padding: 20px 40px;
  margin-bottom: 40px;
  min-height: calc(100% - 30px);
}

header {
  min-height: 100%;
  margin: 0 5%;
  justify-content: space-between;
  position: relative;
}
  
  header .info-personal {
    margin: 40px 0;
  }

    header .info-personal #headerH1 {
      min-width: 275px;
      color: var(--grey-a060);
      font-weight: 400;
      margin: -20px 0 40px 103px;
    }

  header .brand-logo {
    width: 370px;
    max-width: 370px;
    height: auto;
  }

  header .profile-photo {
    width: 260px;
    max-width: 260px;
    height: 100%;
    margin-left: 10px;
  }
  
  header .social-media {
    background-color: var(--grey-005);
    border-radius: 10px;
    height: 130px;
    padding: 20px 30px;
    margin-bottom: 30px;
    text-align: center;
  }

    header .social-media__item {
      padding: 5px 10px;
      margin: 0 5px 0 5px;
      border: 1px solid transparent;
      border-radius: 10px;
      /* transition: all 1s ease-in-out; */
    }

    header .social-media__item:hover, .social-media__email:hover {
      background-color: var(--white);
      border: 1px solid var(--grey-a010);
      transform: scale(1.05);
    }

    header .social-media__email {
      display: block;
      width: 100%;
      border: 1px solid transparent;
      border-radius: 10px;
      padding: 10px 12px;
    }

    header .social-media__email span {
      font-size: 18px;
      font-weight: 500;
      background: var(--gabi-gradient);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }


  #about p {
    margin-bottom: 20px;
  }

.maintenance-warning {
  border-radius: 10px;
  padding: 20px 30px;
  border: 1px solid var(--gabi-orange);
  display: flex;
  margin: 30px 0;
  justify-content: flex-start;
  background-color: white;
  align-items: center;
}

  .maintenance-icon {
    width: 30px;
    margin-right: 20px;
    -webkit-animation: rotateIcon 6s ease-in-out; /* Safari 4.0 - 8.0 */
    animation: rotateIcon 6s ease-in-out;
    animation-delay: 1s;
    animation-iteration-count: 5;
  }

  .maintenance-warning p {
    color: var(--grey);
  }

footer {
  position: fixed;
  bottom: 0;
  right: 0;
  margin-right: 5%;
  margin-bottom: 20px;
}

#about #copyright {
  margin: 0;
  color: var(--grey-a060);
}

#loader {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1000;
  transition: all 1s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: var(--grey-a060);
}

/* ---- ANIMAÇÃO */

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  min-width: 300px;
  z-index: -1;
  }
  
  .overlay .triangle {
  display: block;
  position: absolute;
  -webkit-animation: movimentodir 4s; /* Safari 4.0 - 8.0 */
  animation: movimentodir 4s;
  position: absolute;
  bottom: -150px;
  right: -300px;
  }
  
  .overlay .dot {
  height: 25px;
  width: 25px;
  background-color: #ef8e30;
  border-radius: 50%;
  display: table;
  position: absolute;
  right: 25%;
  top: 5%;
  -webkit-animation: movimento 4s; /* Safari 4.0 - 8.0 */
  animation: movimento 4s;
  }
  
  .overlay .dot-outline {
  height: 30px;
  width: 30px;
  border-color: #ef8e30;
  border-radius: 50%;
  display: table;
  position: absolute;
  right: 15%;
  top: 10%;
  border-style: solid;
  -webkit-animation: movimentocima 4s; /* Safari 4.0 - 8.0 */
  animation: movimentocima 4s;
  }
  
  /* Safari 4.0 - 8.0 */
  @-webkit-keyframes movimento {
    from {right: 18%;}
    to {right: 25%;}
  }
  
  /* Standard syntax */
  @keyframes movimento {
    from {right: 18%;}
    to {right: 25%;}
  }
  
  /* Safari 4.0 - 8.0 */
  @-webkit-keyframes movimentocima {
    from {top:2%;}
    to {top: 10%;}
  }
  
  /* Standard syntax */
  @keyframes movimentocima {
    from {top:2%;}
    to {top: 10%;}
  }
  
  /* Safari 4.0 - 8.0 */
  @-webkit-keyframes movimentodir {
    from {right: -400px;}
    to {right: -300px;}
  }
  
  /* Standard syntax */
  @keyframes movimentodir {
    from {right: -400px;}
    to {right: -300px;}
  }

    /* Safari 4.0 - 8.0 */
    @-webkit-keyframes rotateIcon {
      from {transform: rotate(0);}
      to {transform: rotate(360deg);}
    }
    
    /* Standard syntax */
    @keyframes rotateIcon {
      0% {
        transform: rotate(0) scale(1);
        filter: saturate(1);
      }

      50% {
        transform: rotate(180deg) scale(1.2);
        filter: saturate(3);
      }
      
      100% {
        transform: rotate(360deg) scale(1);
        filter: saturate(1);
      }
    }

  @media (max-width: 1400px) {
    #about {
      max-width: 100%;
    }
  }  

  @media (max-width: 1200px) {

    main {
      flex-direction: column;
    }

    section {
      width: 100%;
    }

    #info {
      width: 100%;
      position: relative;
    }

    #about {
      position: relative;
      margin: 0;
      width: 100%;
      padding: 0;
    }

    .card {
      margin: 40px 5% 60px 5%;
      min-height: auto;
    }

    .info-personal {
      flex: 1;
    }

    .profile-photo {
      margin-right: -30px;
    }

    footer {
      position: absolute;
    }

    header .social-media {
      margin: 0;
    }

    #about > p:last-of-type {
      margin-bottom: 70px;
    }

  }

  @media (max-width: 680px) { 
    
    #info {
      min-width: 100%;
    }

    header div > div {
      flex-wrap: wrap;
      justify-content: center;
    }

    header .info-personal {
      flex: none;
      margin: 30px 5%;
      width: 100%;
      text-align: center;
    }

    header .brand-logo {
      width: 95%;
    }

    header .profile-photo {
      margin-left: 8px;
      margin-top: -20px;
    }

    header .info-personal #headerH1 {
      min-width: auto;
      margin: 15px auto 25px auto;
    }

    .maintenance-warning {
      justify-content: center;
      text-align: center;
    }

    .maintenance-icon {
      margin: 0;
      margin-bottom: 15px;
    }

    .card {
      padding: 10px 25px;
    }
  }
  